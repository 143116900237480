import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'

// import imageOne from '../../assets/frontend/images/resource/About-us.jpg'
// import imageOne from '../../assets/frontend/images/resource/About-us.jpg'

function About(props) {
    const [content, setContent] = useState([]);

    useEffect(() => {
       setContent(props.about)
    }, []);
  return (
    <section className="feature-service sec-padd2" id="about">
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12 column style-1">
                    <div className="section-title">
                        <h3>About iPeople</h3>
                    </div>
                    <figure className="img-box">
                        <Link to="#"><img src={'https://ideajobs.ipeoplelimited.com/'+content.about_image} alt=""/></Link>
                    </figure>
                    <div className="text">
                        <p>{content.about_summary}</p>
                    </div>
                    <div className="link d-none"><Link to="#" className="thm-btn-tr style-2">Know More</Link></div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 column style-2">
                    <div className="section-title">
                        <h2>What We Do</h2>
                    </div>
                    <div className="content">
                        <ul className="list">
                            
                            {
  content.what_we_do 
    ? content.what_we_do.split(",").map((place,index) => <li key={index}><Link to="#"><i className="fa fa-check-circle-o"></i>{place}</Link></li>) 
    : ""
}
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
  )
}

export default About