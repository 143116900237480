import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import serviceImageOne from '../../assets/frontend/images/service/it-people-out.jpg';
import serviceImageTwo from '../../assets/frontend/images/service/doc-verification.jpg';
import serviceImageThree from '../../assets/frontend/images/service/Document-management.jpg';
import serviceImageFour from '../../assets/frontend/images/service/Document-prcessing.jpg';
import serviceImageFive from '../../assets/frontend/images/service/epublishing.jpg';
import serviceImageSix from '../../assets/frontend/images/service/automatice.jpg';


const options = {
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    navText: ["<", ">"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 5,

        }
    },
};

function Services({services}) {
    const [content, setContent] = useState([]);

    useEffect(() => {
       setContent(services)
    }, [services]);

  return (
    <section className="service sec-padd2" id="service">
        <div className="container">
            
            <div className="section-title">
                <h2>Our Services</h2>
            </div>

            <OwlCarousel className='owl-theme service_carousel' loop={true} dots={false} margin={10} items={4} nav {...options}>

                {
                    services ? services.map((item, index) =>
                        <article className="single-column" key={index}>
                            <div className="item">
                                <figure className="img-box">
                                    <img src={'https://ideajobs.ipeoplelimited.com/'+item.image} alt={item.heading}/>
                                    <figcaption className="default-overlay-outer">
                                        <div className="inner">
                                            <div className="content-layer">
                                                <Link to="#" className="thm-btn thm-tran-bg d-none">read more</Link>
                                            </div>
                                        </div>
                                    </figcaption>
                                </figure>
                                <div className="content center">
                                    <h5>Service #{index+1}</h5>
                                    <Link to="#"><h4>{item.heading}</h4></Link>
                                </div>
                            </div>
                        </article>
                    ) 
                    : ""
                }
            
            </OwlCarousel> 
        </div>
    </section>
  )
}


export default Services
