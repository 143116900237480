import React from 'react'

import Header from "../../layouts/frontend/Header";
import Topbar from "../../layouts/frontend/Topbar";
import Footer from "../../layouts/frontend/Footer";
import JobSection from '../../components/frontend/JobSection';

function JobList() {
  return (
   <div>
        <Topbar/>
        <Header/>
        <JobSection/>
        <Footer/>
   </div>
  )
}

export default JobList