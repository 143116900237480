import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import styles from './Grid.module.css';

import ImageOne from '../../assets/frontend/images/clients/Project-1.jpg'
import ImageTwo from '../../assets/frontend/images/clients/clients-2.jpg'
import ImageThree from '../../assets/frontend/images/clients/3.png'
import ImageFour from '../../assets/frontend/images/clients/4.png'
import ImageFive from '../../assets/frontend/images/clients/5.png'

const options = {
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 5,

        }
    },
};

function Client({client}) {
    
    // const [content, setContent] = useState([]);

    // useEffect(() => {
    //    setContent(client)
    // }, [client]);

    // console.log(content)
    
  return (
    <section class="clients-section sec-padd" id="client">
        <div class="container">
            <div class="section-title">
                <h2>Our Projects</h2>
            </div>
            <div className='row mt-4'>

                {
                    client ? client.map((item, index) =>
                        <div className='col-md-3 col-sm-4' key={index}>
                            <div className='text-center election-commission'>
                                <a href={item.link} target="_blank">
                                    <img src={'https://ideajobs.ipeoplelimited.com/'+ item.project_image} alt={item.text}/>
                                </a>

                                {
                                    item.is_ongoing == 1 ? <p className='text-danger text-bold'>*Our Ongoing Project</p> : <p className='text-danger text-bold'>&nbsp;</p>
                                }
                                

                                <a className='btn' href={item.link} target="_blank">
                                    See Details
                                </a>
                            </div>
                        </div>
                    )
                     : ""
                }

                
            </div>
        </div>  
    </section>
  )
}

export default Client