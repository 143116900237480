import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
  Link,
  useLocation,
} from "react-router-dom";
import { WindowView, SmoothScroll } from "./ViewPort";
import { HashLink } from "react-router-hash-link";
// import logoImage from "../../assets/frontend/images/logo/logoNew.png";
// import logoImage from "../../assets/frontend/images/logo/iPeopleLogoTwo.svg";
import logoImage from "../../assets/frontend/images/logo/iPeopleLogoN.png";

const Header = ({general}) => {
    const [isNavExpanded, setIsNavExpanded] = useState(false);
  const { y_top } = WindowView();
  const { pathname } = useLocation();
  let timer = 0;

  //NAVI WITH WIDTH 800px
  useEffect(() => {
    const links = document.querySelectorAll(".link-con .navi-link");
    const allLis = document.querySelectorAll(".link-con li");

    if (pathname !== "/") {
      timer = 100;
    }

    //TOGGLE LINK ACTIVE
    links.forEach((link, i) => {
      link.addEventListener("click", () => {
        //SMOOTH SCROLL
        const targetId = link.getAttribute("name");
        const duration = 1000;
        setTimeout(() => {
          SmoothScroll({ targetId, duration });
          timer = 0;
        }, timer);

        allLis.forEach((li, i) => {
          if (li.classList.contains("active")) {
            li.classList.remove("active");
            link.parentElement.classList.add("active");
          }
        });
      });
    });
  }, []);

  //TOGGLE NAV BACKGROUND COLOR
  useEffect(() => {
    const navigation = document.querySelector("nav");

    if (y_top > 0) {
      navigation.classList.add("bg-light");
      setIsNavExpanded(isNavExpanded);
    } else {
      navigation.classList.remove("bg-light");

    }
  }, [y_top]);

  function handleClick() {
    setIsNavExpanded(!isNavExpanded);
  }

  return (
    <section className="theme_menu stricky">
      <div className="container">
        <div className="row">
          <div className="col-sm-4">
            <div className="main-logo link-con">
              <Link to="/" className="navi-link" name="slider">
                <img src={'https://ideajobs.ipeoplelimited.com/'+general.logo} alt="Home" />
              </Link>
            </div>
          </div>
          <div className="col-sm-8 menu-column">
            <nav className="navigation menuzord" id="main_menu">
            <button
                    className="hamburger"
                    onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                    }}
                >
                    {/* icon from Heroicons.com */}
                    <svg
                    xmlns="https://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="white"
                    >
                    <path
                        fillRule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                        clipRule="evenodd"
                    />
                    </svg>
                </button>
                <div
                    className={
                    isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
                    }
                >
              <ul className="menuzord-menu link-con">
                <li className="active">
                  <Link to="/" className="navi-link" name="slider" onClick={() => handleClick()}>
                    Home
                  </Link>
                </li>

                <li>
                  <Link to="/" className="navi-link" name="about" onClick={() => handleClick()}>
                    About us
                  </Link>
                </li>

                <li>
                  <Link to="/" className="navi-link" name="service" onClick={() => handleClick()}>
                    services
                  </Link>
                </li>

                <li>
                  <Link to="/" className="navi-link" name="client" onClick={() => handleClick()}>
                    Projects
                  </Link>
                </li>

                <li>
                  <Link to="/" className="navi-link" name="footer" onClick={() => handleClick()}>
                    Contact us
                  </Link>
                </li>
              </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
