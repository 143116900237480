import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import MasterLayout from "./layouts/frontend/MasterLayout";
import JobList from './pages/frontend/JobList'

function App() {
  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/" element={<MasterLayout/>}/>
          <Route path="/JobList" element={<JobList />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
