import React from "react";

import RevSlider, { Slide, Caption } from 'react-rev-slider';

import slider1 from "../../assets/frontend/images/slider/s1.jpg";
import slider4 from "../../assets/frontend/images/slider/1.jpg";
import slider2 from "../../assets/frontend/images/slider/s2.jpg";
import slider3 from "../../assets/frontend/images/slider/s3.jpg";
import sliderLogo from "../../assets/frontend/images/slider/logo.png";


const config = {
    sliderType:"standard",
    sliderLayout:"auto",
    dottedOverlay:"yes",
    delay: 7000,
    navigation: {
        arrows:{enable:true,
                left: {
                h_align: "left",
                v_align: "center",
                h_offset: 60,
                v_offset: 0
            },
            right: {
                h_align: "right",
                v_align: "center",
                h_offset: 60,
                v_offset: 0
            }

        } 
    }, 
    gridwidth: [1200, 940, 720, 480],
    gridheight: [683, 650, 630, 600],
    lazyType: "none",
    parallax: {
        type: "mouse",
        origo: "slidercenter",
        speed: 2000,
        levels: [2, 3, 4, 5, 6, 7, 12, 16, 10, 50],
    },
    shadow: 0,
    spinner: "off",
    stopLoop: "off",
    stopAfterLoops: -1,
    stopAtSlide: -1,
    shuffle: "off",
    autoHeight: "off",
    hideThumbsOnMobile: "off",
    hideSliderAtLimit: 0,
    hideCaptionAtLimit: 0,
    hideAllCaptionAtLilmit: 0,
    debugMode: false,
    fallbacks: {
        simplifyAll: "off",
        nextSlideOnWindowFocus: "off",
        disableFocusListener: false,
    }
};

function RevSliders({sliders}){
    return(
        <section className="rev_slider_wrapper" id="slider">
            <RevSlider config={config} class="rev_slider" data-version="5.0">
                { sliders ? sliders.map((item, index) => 
                    <Slide
                    src={'https://ideajobs.ipeoplelimited.com/'+item.banner_image}
                    alt={"slider"+index+1}
                    width="1920" height="683"
                    data-bgposition={item.banner_image_position}
                    data-bgfit="cover" 
                    data-bgrepeat="no-repeat" 
                    data-bgparallax="1" 
                    key={index}
                >   
                    <Caption
                        class="tp-caption tp-resizeme"
                        data-x={item.caption_position_x_axis} data-hoffset={item.caption_position_h_offset}
                        data-y={item.caption_position_y_axis} data-voffset={item.caption_position_v_offset}
                        data-transform_idle="o:1;"         
                        data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;" 
                        data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"  
                        data-splitin="none" 
                        data-splitout="none"  
                        data-responsive_offset="on"
                        data-start="500">
                        <div class="slide-content-box">
                            <h1>{item.heading}</h1>
                        </div>
                    </Caption>
                    <Caption
                        class="tp-caption tp-resizeme tp-button"
                        data-x={item.button_position_x_axis} data-hoffset={item.button_position_h_offset} 
                        data-y={item.button_position_y_axis} data-voffset={item.button_position_v_offset} 
                        data-transform_idle="o:1;"                         
                        data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" 
                        data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"                     
                        data-splitin="none" 
                        data-splitout="none" 
                        data-responsive_offset="on"
                        data-start="2600">
                        <div class="slide-content-box">
                            <div class="button">
                                <a class="thm-btn" href="#consultation">CONTACT US</a>     
                            </div>
                        </div>
                    </Caption>
                    
                </Slide>
                ) : "" 
                }
                
            </RevSlider>
        </section>
    );
}

export default RevSliders;