import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor'
import $ from 'jquery';
import styles from './Grid.module.css';

import Background from '../../assets/frontend/images/background/1st-background.jpg';

function FactCounter(props) {
    const [counters, setCounters] = useState([]);

    useEffect(() => {
        setCounters(props.counter)
    }, []);
  return (
    
    <section className="fact-counter sec-padd" style={{backgroundImage: "url(" + Background + ")"}}>
        <div className="container">
            <div className="clearfix">
                <div className={[styles.rowGrid , 'counter-outer', 'clearfix'].join(' ')}>
                    <article className={[styles.col_md, 'column', 'counter-column', 'wow', 'fadeIn'].join(' ')} data-wow-duration="0ms">
                        <div className="item">
                            <div className="count-outer">
                                <span className="count-text">
                                    <CountUp end={counters.total_experience} duration={3} redraw={true}>
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>+
                                </span>
                            </div>
                            <h4 className="counter-title">Years of experience</h4>
                            <div className="icon"><i className="icon-technology3"></i></div>
                        </div>
                            
                    </article>
                    
                    <article className={[styles.col_md, 'column', 'counter-column', 'wow', 'fadeIn'].join(' ')} data-wow-duration="0ms">
                        <div className="item">
                            <div className="count-outer">
                                <span className="count-text">
                                    <CountUp end={counters.total_employee} duration={3} redraw={true}>
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>+
                                </span>
                            </div>
                            <h4 className="counter-title">Employees Supplied</h4>
                            <div className="icon"><i className="icon-people3"></i></div>
                        </div>
                    </article>
                    
                    <article className={[styles.col_md, 'column', 'counter-column', 'wow', 'fadeIn'].join(' ')} data-wow-duration="0ms">
                        <div className="item">
                            <div className="count-outer">
                                <span className="count-text">
                                    <CountUp end={counters.currently_working} duration={3} redraw={true}>
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>+
                                </span>
                            </div>
                            <h4 className="counter-title">Currently Working</h4>
                            <div className="icon"><i className="icon-people"></i></div>
                        </div>
                    </article>
                    
                    <article className={[styles.col_md, 'column', 'counter-column', 'wow', 'fadeIn'].join(' ')} data-wow-duration="0ms">
                        <div className="item">
                            <div className="count-outer">
                                <span className="count-text">
                                    <CountUp end={counters.satisfied_customers} duration={3} redraw={true}>
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                </span>%
                            </div>
                            <h4 className="counter-title">Satisfied Customers</h4>
                            <div className="icon"><i className="icon-sports"></i></div>
                        </div>
                    </article>

                </div>
            </div>
        </div>
    </section>
  )
}

export default FactCounter