import React, {useEffect, useState} from 'react'

function Choose(props) {
    const [choose, setChoose] = useState([]);

    useEffect(() => {
        setChoose(props.choose)
    }, []);
  return (
    <section className="whychoos-us sec-padd2" id="choose">
        <div className="container">
            
            <div className="section-title center">
                <h2>{choose.heading}</h2>
                <div className="text">
                    <p>{choose.choose_summary}</p>
                </div>
            </div>
                
            
            <div className="row clearfix">
                <article className="column col-md-4 col-sm-6 col-xs-12">
                    <div className="item">
                        <div className="icon_box">
                            <span className={choose.item1_icon}></span>
                        </div>
                        <a><h4>{choose.item1_name}</h4></a>
                        <div className="text d-none">
                            <p>{choose.item1_text}</p>
                        </div>
                        <div className="count">01</div>
                    </div>
                </article>
                <article className="column col-md-4 col-sm-6 col-xs-12">
                    <div className="item">
                        <div className="icon_box">
                            <span className={choose.item2_icon}></span>
                        </div>
                        <a><h4>{choose.item2_name}</h4></a>
                        <div className="text d-none">
                            <p>{choose.item2_text}</p>
                        </div>
                        <div className="count">02</div>
                    </div>
                </article>
                <article className="column col-md-4 col-sm-6 col-xs-12">
                    <div className="item">
                        <div className="icon_box">
                            <span className={choose.item3_icon}></span>
                        </div>
                        <a><h4>{choose.item3_name}</h4></a>
                        <div className="text d-none">
                            <p>{choose.item3_text}</p>
                        </div>
                        <div className="count">03</div>
                    </div>
                </article>
                
            </div>
        </div>
    </section>
  )
}

export default Choose