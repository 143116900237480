import React, { useState, Component } from 'react'
import PropTypes from "prop-types";
import SelectBox from './Selectbox';
import axios from 'axios';

// import Background from '../../assets/frontend/images/background/contact-us.jpg';
import Background from '../../assets/frontend/images/background/contact-section.png';

const API_PATH = 'https://ipeoplelimited.com/index.php';

class Consultation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form_name: '',
            form_email: '',
            form_message: '',
            message: '',
            mailSent: false,
            error: null
        }
      }

      onNameChange(event) {
        this.setState({form_name: event.target.value})
      }

      onSelectChange(event) {
        this.setState({form_subject: event.target.value})
      }
    
      onEmailChange(event) {
        this.setState({form_email: event.target.value})
      }
    
      onMessageChange(event) {
        this.setState({form_message: event.target.value})
      }

    //   handleFormSubmit( event ) {
    //     console.log(this.state);
    //     event.preventDefault();
    //     axios({
    //         method: 'post',
    //         url: `${API_PATH}`,
    //         headers: { 'content-type': 'application/json' },
    //         data: this.state
    //     })
    //         .then(result => {
    //         this.setState({
    //             mailSent: result.data.sent
    //         })
    //         })
    //         .catch(error => this.setState({ error: error.message }));
    // }

    handleSubmit(e) {
        e.preventDefault();
      
        fetch('https://ipeoplelimited.com/mail.php', {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          }).then(
          (response) => (response.json())
            ).then((response)=> {
                console.log(response);
          if (response.status === 'success') {
            alert("Your Query Submitted Successfully!"); 
            this.resetForm()
          } else if(response.status === 'fail') {
            alert("Something Went Wrong!!")
          }
        })
    }

    resetForm(){
        this.setState({form_name: '', form_email: '', form_message: ''})
    }


  render() {
    return (
        <section className="consultations sec-padd" style={{backgroundImage: "url(" + Background + ")"}} id="consultation">
        <div className="container">
            <div className="row">
                <div className="col-md-4 col-sm-6 col-xs-12">   
                    <div className="contact-info">
                        <div className="section-title">
                            <h3>Contact Details</h3>
                        </div>
                        <div className="text">
                            <p>All good things start with a Hello. <br/>Give us a shout today!</p>
                        </div>
                        <div className="widget-content">
                            <ul className="list-info">
                                <li><span className="fa fa-phone"></span>Phone: +88 01894 882 828</li>
                                <li><span className="fa fa-envelope"></span>Email: info@ipeoplelimited.com</li>
                                <li><span className="fa fa-clock-o"></span>Sun to Thu: 9.00am to 6.00pm</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <div className="section-title">
                        <h2>Let's get in touch!</h2>
                    </div>
                    <div className="default-form-area">
                        <form id="contact-form" className='default-form' onSubmit={this.handleSubmit.bind(this)} method="POST">
                            <div className="row-10 clearfix">
                                <div className="col-md-4 co-sm-6 col-xs-12 column">
                                    <div className="form-group">
                                        <input type="text" 
                                        name="form_name" 
                                        className="form-control"  
                                        placeholder="Name *" 
                                        value={this.state.form_name} 
                                        onChange={this.onNameChange.bind(this)}
                                        required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" 
                                        name="form_email" 
                                        className="form-control required email" 
                                        value={this.state.form_email} 
                                        placeholder="Email Address *" 
                                        onChange={this.onEmailChange.bind(this)}
                                        required
                                        />
                                    </div>
                                    <div className="form-group d-none">
                                        <div className="select-box">
                                            <select class="text-capitalize selectpicker form-control" name="form_subject" data-style="g-select" value={this.state.form_subject} onChange={this.onSelectChange.bind(this)}>
                                                <option value="Enquiry About" selected>Enquiry About</option>
                                                <option value="Enquiry Team">Enquiry Team</option>
                                                <option value="Enquiry Service">Enquiry Service</option>
                                            </select>
                                        </div>
                                            
                                    </div>
                                </div>
                                <div className="col-md-8 co-sm-6 col-xs-12 column">
                                    <div className="form-group style-2">
                                        <textarea name="form_message" 
                                        className="form-control textarea required"
                                        value={this.state.form_message}  
                                        onChange={this.onMessageChange.bind(this)}
                                        placeholder="Your Message..." required></textarea>
                                        <input id="form_botcheck" name="form_botcheck" className="form-control" type="hidden" value=""/>
                                        <button className="thm-btn thm-color" 
                                        type="submit" 
                                        data-loading-text="Please wait..." value="Submit"
                                        ><i className="fa fa-paper-plane"></i></button>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    {this.state.mailSent &&
                                        <div>Thank you for contcting us.</div>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
  }
}

export default Consultation