import React from 'react'
import Pdf from '../../assets/frontend/file/High Tech Park notice.pdf'

function JobSection() {
  return (
    <section className="service style-2 sec-padd pb-5 mb-5">
        <div className="container"> 
            <div className="row">
                <div className='col-md-1'></div>
                <div className="col-lg-10 col-md-10 col-sm-12">
                    <div className="service-sidebar">
                       
                        <br/><br/>
                        <div className="inner-title">
                            <h3>Current Offerings</h3>
                        </div>
                        <div className="brochures">

                            <ul className="brochures-lists">
                                <li><a href={Pdf} target='_blank'><span className="fa fa-file-pdf-o"></span>[13-07-22] নিয়োগ বিজ্ঞপ্তি - বাংলাদেশ হাই -টেক পার্ক কর্তৃপক্ষ</a></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default JobSection