import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import Image from '../../assets/frontend/images/resource/pop-up.png';

class Popup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalState: true
        };

        this.handleShow = this.handleShow.bind(this);
    }

    handleShow() {
        this.setState({ modalState: !this.state.modalState });
    }

    
  render() {
    return (
      <div>
        <div className={"modal fade" + (this.state.modalState ? " show d-block" : " d-none")} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={this.handleShow}>
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Link to="/JobList" style={{width:'100%'}}>
                            <img src={Image} alt='Image' style={{width:'100%'}}/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}

export default Popup