import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import "../../assets/frontend/css/bootstrap.min.css";
import "../../assets/frontend/css/bootstrap-select.min.css";
import "../../assets/frontend/css/jquery.bootstrap-touchspin.css";
import "../../assets/frontend/css/font-awesome.css";
import "../../assets/frontend/css/flaticon.css";
import "../../assets/frontend/css/icomoon.css";
import "../../assets/frontend/css/settings.css";
import "../../assets/frontend/css/layers.css";
import "../../assets/frontend/css/navigation.css";
import "../../assets/frontend/css/owl.carousel.css";
import "../../assets/frontend/css/jquery.bxslider.css";
import "../../assets/frontend/css/flexslider.css";
import "../../assets/frontend/css/jquery-ui.css";
import "../../assets/frontend/css/jquery.fancybox.css";
import "../../assets/frontend/css/polyglot-language-switcher.css";
import "../../assets/frontend/css/animate.min.css";
import "../../assets/frontend/css/nouislider.pips.css";
import "../../assets/frontend/css/menuzord.css";
import "../../assets/frontend/css/magnific-popup.css";
import "../../assets/frontend/css/imagehover.min.css";
import "../../assets/frontend/css/navbar.css";

import "../../assets/frontend/css/style.css";
import "../../assets/frontend/css/responsive.css";

import Header from "./Header";
import Topbar from "./Topbar";
import Footer from "./Footer";
import Popup from "./Popup";
import RevSliders from "../../components/frontend/RevSlider";
import Choose from "../../components/frontend/Choose";
import Services from "../../components/frontend/Services";
import FactCounter from "../../components/frontend/FactCounter";
import About from "../../components/frontend/About";
import Consultation from "../../components/frontend/Consultation";
import Client from "../../components/frontend/Client";

const MasterLayout = () => {
    const [contents, setContent] = useState([]);

    useEffect(() => {
        fetch('https://ideajobs.ipeoplelimited.com/api/all-data',{
            "method" : "get",
            "headers" : {
                "Api_KEY" : "H@McQfTjWnZr4u7x!A%D*G-KaNdRgUkXp2s5v8y/B?E(H+MbQeShVmYq3t6w9z$C&F)J@NcRfUjWnZr4u7x!A%D*G-KaPdSgVkYp2s5v8y/B?E(H+MbQeThWmZq4t7w9"
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data)
                setContent(data);
                // console.log(data.projects)
            })
            .catch((err) => {
            console.log(err.message);
            });
    }, []);

    return (
        <div className="boxed_wrapper">

            <>
            { contents.general != null && <Topbar general={contents.general}/> }
            </>

            <>
            { contents.general != null && <Header general={contents.general}/> }
            </>

            <>
            { contents.banners != null && <RevSliders sliders={contents.banners}/> }
            </>
            

            <>
            {contents.choose != null && <Choose choose={contents.choose}/>}
            </>

            <>
            {contents.about != null && <About about={contents.about}/>}
            </>

            <>
            {contents.counter != null && <FactCounter counter={contents.counter}/>}
            </>

            <>
            { contents.services != null &&  <Services services={contents.services}/> }
            </>

            <>
            { contents != null && <Consultation/>}
            </>

            <>
            { contents.projects != null &&  <Client client={contents.projects}/>}
            </>

            {/* <Popup/> */}

            <>
            { contents.general!=null && contents.about!=null &&  <Footer general={contents.general} whatWeDo={contents.about}/> }
            </>
            

        </div>
    );
}

export default MasterLayout;