import React from "react";
import {Link} from "react-router-dom"

const Footer = (footerProps) => {
    console.log(footerProps.general)
    console.log(footerProps.whatWeDo)
    return (
        <footer className="main-footer" id="footer">
            <div className="widgets-section">
                <div className="container">
                    <div className="row">
                        <div className="big-column col-md-12 col-sm-12 col-xs-12">
                            <div className="row clearfix">
                                
                                <div className="footer-column col-md-4 col-sm-6 col-xs-12">
                                    <div className="footer-widget about-widget">
                                        <h3 className="footer-title">About Us</h3>
                                        
                                        <div className="widget-content">
                                            <div className="text"><p>{footerProps.general.footer_summary}</p> </div>
                                            <div className="link d-none">
                                                <Link to="#" className="default_link">More About us <i className="fa fa-angle-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer-column col-md-4 col-sm-6 col-xs-12">
                                    <div className="footer-widget links-widget">
                                        <h3 className="footer-title">Our Services</h3>
                                        <div className="widget-content">
                                            <ul className="list">
                                                {
                                                    footerProps.whatWeDo.what_we_do ? footerProps.whatWeDo.what_we_do.split(',').map((item,index) => 
                                                        <li key={index}><Link to="#">{item}</Link></li>
                                                    ) 
                                                    : ""
                                                }

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="footer-column col-md-4 col-sm-6 col-xs-12">
                                    <div className="footer-widget contact-widget">
                                        <h3 className="footer-title">Contact us</h3>
                                        <div className="widget-content">
                                            <ul className="contact-info">
                                                <li><span className="icon-signs"></span>{footerProps.general.address}</li>
                                                <li><span className="icon-phone-call"></span> Phone: +{footerProps.general.phone}</li>
                                                <li><span className="icon-e-mail-envelope"></span>{footerProps.general.email}</li>
                                            </ul>
                                        </div>
                                        <ul className="social">
                                            <li><a href={footerProps.general.fb_id} target="_blank"><i className="fa fa-facebook"></i></a></li>
                                            <li><Link to={footerProps.general.linkedin}><i className="fa fa-linkedin"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
            <section className="footer-bottom">
                <div className="container">
                    <div className="text-center copy-text">
                        <p>Copyrights © 2022 All Rights Reserved. Powered by  <Link to="#"> iPeople Limited.</Link></p>
                        
                    </div>
                </div>
            </section>
            
        </footer>
    );
}

export default Footer;