import React from 'react'

class SelectBox extends React.Component{

    constructor(){
        super();
        this.state = {
            city:null,
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        
        this.setState({
            city: event.target.value
        });
        
    }

    submit(){
        console.warn(this.state)
    }

    render(){
        return(
            <select class="text-capitalize selectpicker form-control required" name="form_subject" data-style="g-select" data-width="100%" onChange={this.handleInputChange}>
                <option value="0" selected>Enquiry About</option>
                <option value="1">Enquiry Team</option>
                <option value="2">Enquiry service</option>
            </select>
        )  
    }
}

export default SelectBox;