import React from "react";
import {Link} from "react-router-dom";

const Topbar = ({general}) => {
    return (
        <header className="top-bar">
            <div className="container">
                <div className="clearfix">
                    <div className="col-left float_left">
                        <ul className="top-bar-info">
                            <li><i className="icon-technology"></i>Phone: +{general.phone}</li>
                            <li><i className="icon-note2"></i>{general.email}</li>
                        </ul>
                    </div>
                    <div className="col-right float_right">
                        <ul className="social d-none">
                            <li>Stay Connected: </li>
                            <li><Link to="#"><i className="fa fa-facebook"></i></Link></li>
                            <li><Link to="#"><i className="fa fa-linkedin"></i></Link></li>
                            <li><Link to="#"><i className="fa fa-twitter"></i></Link></li>
                        </ul>
                    </div>     
                </div>
            </div>
        </header>
    );
}

export default Topbar;